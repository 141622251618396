import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { useEffect, useMemo, useState } from "react";
import SEOTags from "~/components/SEOTags";
import { QUERY_GET_CMS_PAGE } from "~/data/cms/gql";
import { GET_STORE_CONFIG_ON_DOCUMENT } from "~/data/home";
import { GET_SCHEMA_HOME } from "~/data/schemas/schema.gql";
import Layout from "~/layouts/ThemeLayout";
import ReMaketing, { REMARKETING_HOME } from "~/services/tracking/ReMaketing";
import styles from "~/styles/pages/Home.module.scss";
import HomeSchema from "~/tracking-pages/home.schema";
import { initializeApollo } from "~/utils/apollo-client";
import { getPageStruct, renderPage } from "~/utils/page";
import { getTheme } from "~/utils/theme";
const path = `~/theme/${getTheme()}/tracking-pages/home.schema`;

// const HomeSchema = dynamic(() => import(path), {
//   ssr: true,
//   loading(loadingProps) {
//     return <div>Loading...</div>;
//   },
// });

const Home = ({
  meta,
  storeConfig,
  configSchema,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const pageStruct = getPageStruct(getTheme(), "home");
  const [component, setComponent] = useState<any>();

  const dataApi: any = useMemo(() => {
    return {};
  }, []);

  useEffect(() => {
    async function fetchComponent() {
      try {
        const fetchedComponents = await renderPage(
          pageStruct.children.sort((a: any, b: any) => a.order - b.order),
          getTheme()
        );

        // Filter and validate components
        setComponent(fetchedComponents.filter((c: any) => !!c));
      } catch (error) {
        console.error("Error fetching components:", error);
      }
    }

    fetchComponent();
  }, [pageStruct.children]);

  const newTitle = [
    process.env.NEXT_PUBLIC_SEO_DEFAULT_BRAND,
    meta?.meta_title || process.env.NEXT_PUBLIC_SEO_DEFAULT_TITLE,
  ]
    .filter((i) => !!i)
    .join(" | ");

  return (
    <Layout isUseMinHeight>
      <SEOTags
        metaTitle={newTitle}
        description={meta?.meta_description}
        keywords={meta?.meta_keywords}
      />
      <HomeSchema config={configSchema} />
      {/* <NextSeo openGraph={seoConfig} /> */}
      <ReMaketing gtagType={REMARKETING_HOME} />
      <div className={styles.homepage}>
        {component?.map((com: any, index: number) => {
          const key = pageStruct.children[index]?.dataKey; // Assuming each child has a key matching SectionsKey
          const props = dataApi[key] || {}; // Get the props for the component from dataApi
          // if (typeof Component !== 'function' && typeof Component !== 'string') {
          //   console.error("Invalid component:", Component);
          //   return null;
          // }
          return {
            ...com,
            props: {
              ...com.props,
              ...props,
            },
          };
        })}
      </div>
      <div></div>
    </Layout>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async () => {
  const apolloClient = initializeApollo({
    httpLinkProps: { useGETForQueries: true },
  });
  try {
    const { data } = await apolloClient.query({
      query: QUERY_GET_CMS_PAGE,
      variables: {
        identifier: "home",
      },
    });
    const { data: dataStoreConfig } = await apolloClient.query({
      query: GET_STORE_CONFIG_ON_DOCUMENT,
      variables: {
        identifier: "home",
      },
    });
    const { data: dataSchema } = await apolloClient.query({
      query: GET_SCHEMA_HOME,
    });

    return {
      props: {
        meta: data?.cmsPage,
        storeConfig: dataStoreConfig?.storeConfig,
        configSchema: dataSchema?.MarketingConfig?.schemaHomepage,
      },
    };
  } catch (error) {
    return {
      props: {
        meta: {},
        storeConfig: {},
      },
    };
  }
};
